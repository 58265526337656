import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'
import { Container } from 'rendition'

const BlogListTemplate = (props) => {
  const {
    data,
    pageContext: { currentPage },
  } = props
  return (
    <Layout>
      <Container>
        <BlogRoll data={data} currentPage={currentPage} />
      </Container>
    </Layout>
  )
}
export default BlogListTemplate

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: $limit
      skip: $skip
      filter: {
        frontmatter: {
          templateKey: { eq: "blog-post" }
          visibility: { eq: "public" }
        }
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          timeToRead
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            featured
            feature_image
            postType
            excerpt
          }
        }
      }
      totalCount
    }
  }
`
